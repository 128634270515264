import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import SEO from '../components/Seo';
import DesktopLayout from '../layouts/Desktop';

const Home = ({ data }) => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    console.log('Fetching from wordpress...');

    // fetch all posts
    // https://developer.wordpress.org/rest-api/reference/
    fetch('https://cms.melkin.eu/wp-json/wp/v2/posts')
      .then(r => r.json())
      .then(posts => {
        // posts.map(async post => {
        //   // console.log('post is', post);
        //   const result = await fetch(`https://cms.melkin.eu/wp-json/acf/v3/posts/${post.id}`);
        //   const { acf } = await result.json();
        //   // console.log(`acf of ${post.id}`, acf);
        //   return { ...post, ...acf };
        // });
        setPosts(posts);
      });

    //fetch all cars
    // https://cms.melkin.eu/wp-json/wp/v2/car?categories=economy-cars&filter[meta_key]=engine&filter[meta_value]=900
    fetch('https://cms.melkin.eu/wp-json/wp/v2/car?categories=economy-cars')
      .then(r => r.json())
      .then(cars => {
        console.log('cars', cars);
      });

    // fetch all cars by taxonomy
    fetch('https://cms.melkin.eu/wp-json/wp/v2/taxonomies/car_groups')
      .then(r => r.json())
      .then(group => {
        console.log('group', group);
      });
    
    // fetch all menus
    // https://wordpress.org/plugins/wp-rest-api-v2-menus/
    fetch('https://cms.melkin.eu/wp-json/menus/v1/menus')
      .then(r => r.json())
      .then(data => {
      // console.log('data', data);
        data.map(async item => {
        // console.log('menu is', item.slug);
          const menuResponse = await fetch(`https://cms.melkin.eu/wp-json/menus/v1/menus/${item.slug}`);
          const menuDetails = await menuResponse.json();
          // console.log('details', menuDetails);
          const menuItems = menuDetails.items.map(({title, slug}) => ({title, slug}));
          const finalMenus = { [item.slug]: menuItems };
          console.log('menus', finalMenus);

        
        });
      });
  }, []);

  console.log('wp posts graphql', data.allWpPost.nodes);

  // Woocommerce
  // ck_7b519ac5827762d4c952844990fbfcb72a593f27 // key
  // cs_4e618aff2ee1cafdd71bb28502a26ab73f04bbc2 // secret
  return (
    <DesktopLayout>
      <SEO title="Welcome to Gatsby" description="Sime gatsby website demo" />
      <h1>Welcome to Gatsby demo</h1>
      <p>
        Below you can find list of blog posts transformed from .md files!
      </p>
      <div>
        {data.allMarkdownRemark.edges.map(({ node }, index) => (
          <article key={index}>
            <h3>{node.frontmatter.title} <small>{node.frontmatter.date}</small></h3>
            <p>{node.excerpt}</p>
            <Link to={node.fields.slug}>Read more</Link>
          </article>
        ))}
      </div>
      <div>
        <h2>Demo Wordpress Posts from REST:</h2>
        {posts.length > 0 ? posts.map(({id, title, content, slug, acf}) =>
          <article key={id}>
            <h5>{title.rendered}</h5>
            {acf?.price && <><strong>Price: </strong><small>{acf?.price} &euro;</small></>}
            <div dangerouslySetInnerHTML={{__html: content.rendered}} />
            <Link to={slug}>Read more</Link>
          </article>
        ) : <>Loading...</>}
      </div>
      <div>
        <h2>Demo Wordpress Posts from GraphQL:</h2>
        {data.allWpPost.nodes.length > 0 ? data.allWpPost.nodes.map(({id, title, content, date, slug}) =>
          <article key={id}>
            <h5>{title}</h5>
            <small>Date: {date}</small>
            <div dangerouslySetInnerHTML={{__html: content}} />
            <Link to={slug}>Read more</Link>
          </article>
        ) : <>Loading...</>}
      </div>
    </DesktopLayout>
  );
};

export const query = graphql`
  {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          html
          excerpt
          frontmatter {
            title
            date
          }
          fields {
            slug
          }
          timeToRead
        }
      }
    },
    allWpPost {
      nodes {
        content
        id
        date
        title
        slug
      }
    }
  }
`;

export default Home;
